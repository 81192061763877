// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loja-tsx": () => import("./../../../src/pages/loja.tsx" /* webpackChunkName: "component---src-pages-loja-tsx" */),
  "component---src-pages-sorteio-tsx": () => import("./../../../src/pages/sorteio.tsx" /* webpackChunkName: "component---src-pages-sorteio-tsx" */),
  "component---src-pages-teste-tsx": () => import("./../../../src/pages/teste.tsx" /* webpackChunkName: "component---src-pages-teste-tsx" */),
  "component---src-templates-answer-template-tsx": () => import("./../../../src/templates/answerTemplate.tsx" /* webpackChunkName: "component---src-templates-answer-template-tsx" */)
}

