import { createSlice } from "@reduxjs/toolkit"
import { languageOptions } from "./language.config"

const browserLanguage =
  typeof window !== `undefined` ? navigator.language.split("-")[0] : "pt"

const value =
  browserLanguage == "pt" ? "pt_br" : browserLanguage == "es" ? "es" : "en"

const initialValue = {
  value: value,
  config: languageOptions[value as keyof typeof languageOptions],
}

export const languageSlice = createSlice({
  name: "language",
  initialState: initialValue,
  reducers: {
    setLanguage: (state, action) => {
      state.value = action.payload
      state.config =
        languageOptions[action.payload as keyof typeof languageOptions]
    },
  },
})

export const { setLanguage } = languageSlice.actions
export default languageSlice.reducer
