export const languageOptions = {
  en: {
    Home: {
      slogan:
        "The solution for those who are tired of writing and folding little pieces of paper for the secret friend draw.",
      description: [
        "Papelzinho is the perfect app to make your secret friend draws with practicality and without real little pieces of paper",
        "Create as many groups as you want, so you can make a different secret friend draw with each group! The number of participants is infinite and everyone can put up to 3 gift options.",
        "How it works",
        "The draw is made in the app itself and all members will receive an email with a link to the reveal of their secret friend. It is on this same site that everyone can write their gift preferences.",
        "Good fun!",
        "Got any questions or suggestions? Talk to us through",
      ],

      rating: "Ratings and reviews",
      reviews: [
        {
          title: "TOP NOTCH!!!",
          body: "Very cool, very easy to do, just enter the names already with the gift option for each secret friend, enter the email and you're done! Draw in the emails and no one sees the other's, very cool! Super recommend! Congratulations to the developer!",
        },
        {
          title: "SOOO good!!!",
          body: "Very good the application!! Super recommended!! The email doesn't take long to arrive, it's super fast and practical and it doesn't have advertising!! I highly recommend it, I have nothing to complain about the app!! Too bad you can't give 6 stars.",
        },
        {
          title: "Excellent",
          body: "Very easy, with many options. It's fantastic, I'll always be using it. The best thing is that it is not necessary for everyone to be gathered in one place and there is no risk of taking your own name. Not even cheat. A tip is to put the SMS sending function.",
        },
        {
          title: "Great",
          body: "I recently had a problem, and I contacted them through Facebook and they responded quickly and solved my problem in a matter of minutes. Congratulations on the app and the service.",
        },
      ],
    },

    Header: {
      store: "Papelzinho's Store",
      title: "Draw | Papelzinho",
    },

    Draw: {
      slogan: "Secret friend draw without folding little pieces of paper",
      store: "Papelzinho's Store",
      greeting: "Hello",
      youAre: "You are in the",
      participants: "participants",
      revealSecretFriend: "Reveal my secret friend",
      whatMyFriendWants: "What my friend wants",
      didntFillGiftList: {
        header: "This participant has not yet filled in the gift list",
        body: "How about giving a touch to the people?",
      },
      edit: "Edit",
      othersParticipantsChoices: "The choices of the other participants",
      ratings: "See the rating of the other participants",
    },

    popUp: {
      warning: "Are you sure no one is spying?",
      seeWhoIDrew: "See who I drew",
      store: "Papelzinho's Store",
      yourFriendIs: "Your friend is...",
      buyGift: "Buy gift",
      giftList: "Gift list",
    },

    participantGiftList: {
      save: "Save",
      edit: "Edit",
      store: "Papelzinho's Store",
      whatMyFriendWants: "What my friend wants",

      didntFillGiftList: {
        header: "This participant has not yet filled in the gift list",
        body: "How about giving a touch to the people?",
      },
      myList: "My list",
      optionsPlaceHolders: ["Option 1", "Option 2", "Option 3", "Comments"],
      giftOptions: "Gift options",
      observations: "Observations",
      saving: "Saving...",
    },

    Footer: {
      store: "Papelzinho's Store",
      aboutUs: "About us",
      download: "Download the App",
    },

    FAQ: {
      title: "Frequently Asked Questions",
    },

    Errors: {
      500: {
        title: "Something went wrong",
        body: "We are working to fix the problem as soon as possible.",
        warning: "Try accessing again later.",
      },

      oops: "Ooops!",
      groupDeleted: "This group was deleted.",
      groupDeletedBody:
        "Get in touch with the secret friend administrator to understand what happened and think of a solution with your group.",
      groupDeletedSuggestion: "We suggest that a new draw be held.",

      emailDeleted: "This email was deleted from the draw.",
      emailDeletedSuggestion:
        "Get in touch with the secret friend administrator to understand what happened.",
      needHelp: "Need help? Access our",
      faq: "frequently asked questions.",
    },
  },

  pt_br: {
    Home: {
      slogan:
        "A solução pra quem está cansado de escrever e dobrar pedacinhos de papel para o sorteio de amigo secreto.",
      description: [
        "O Papelzinho é o app perfeito para realizar seus sorteios de amigo secreto com praticidade e sem papelzinho de verdade",
        "Crie quantos grupos quiser, assim dá pra fazer um amigo secreto diferente com cada galera! O número de participantes é infinito e todos podem colocar até 3 opções de presentes.",
        "Como funciona",
        "O sorteio é realizado no próprio app e todos os integrantes receberão um e-mail com link direcionado para a revelação do seu amigo oculto. É nesse mesmo site que cada um pode escrever suas preferências de presente.",
        "Boa diversão!",
        "Pintou alguma dúvida ou tem sugestões? Fala com a gente pelo",
      ],

      rating: "Avaliações e opiniões",

      reviews: [
        {
          title: "TOP DEMAIS!!!",
          body: "Muito legal, muito fácil de fazer, basta inserir os nomes já com opção de presentes para cada amigo secreto, inserir o e-mail e pronto! Sorteia nos e-mails e nenhum vê o do outro, legal demais! Super recomendo! Parabéns ao desenvolvedor!",
        },
        {
          title: "MUITOOO bom!!!",
          body: "Muito bom o aplicativo!! Super recomendo!! O email n demora a chegar, é super rápido e prático e n tem propaganda!! Recomendo demais, n tenho nada a reclamar sobre o app!! pena q n tem como dar 6 estrelas.",
        },
        {
          title: "Excelente",
          body: "Muito fácil, com muitas opções. É fantástico estarei sempre usando. O melhor que não precisa estar todos reunidos em um lugar e nem tem o risco de tirar o próprio nome. Nem mesmo fazer marmelada. Uma dica é colocar a função de envio por SMS.",
        },
        {
          title: "Ótimo",
          body: "Tive um problema recentemente, e entrei em contato com eles através do Facebook e me responderam rápido e resolveram o meu problema em questão de minutos. Estão de parabéns pelo app e pelo atendimento.",
        },
      ],
    },

    Header: {
      store: "Loja Papelzinho",
      title: "Sorteio | Papelzinho",
    },

    Draw: {
      slogan: "Amigo secreto sem dobrar papelzinho",
      store: "Papelzinho's Store",
      greeting: "Olá",
      youAre: "Você está no",
      participants: "participantes",
      revealSecretFriend: "Revelar meu amigo secreto",
      othersParticipantsChoices: "As escolhas dos outros participantes",
      ratings: "Veja a avaliação dos outros participantes",
    },

    popUp: {
      warning: "Tem certeza que não tem ninguém espiando?",
      seeWhoIDrew: "Ver quem eu tirei",
      store: "Loja Papelzinho",
      yourFriendIs: "Seu amigo ééé...",
      buyGift: "Comprar presente",
      giftList: "Lista de presentes",
    },

    participantGiftList: {
      save: "Salvar",
      edit: "Editar",
      store: "Loja Papelzinho",
      whatMyFriendWants: "O que meu amigo quer ganhar",

      didntFillGiftList: {
        header: "Esse participante ainda não preencheu a lista de presentes",
        body: "Que tal dar um toque no pessoal?",
      },
      myList: "Minha lista",
      giftOptions: "Opções de presente",
      optionsPlaceHolders: ["Opção 1", "Opção 2", "Opção 3", "Comentários"],
      observations: "Observações",
      saving: "Salvando...",
    },

    Footer: {
      store: "Loja Papelzinho",
      aboutUs: "Sobre nós",
      download: "Baixe o App",
    },

    FAQ: {
      title: "Perguntas Frequentes",
    },

    Errors: {
      500: {
        title: "Algo deu errado",
        body: "Estamos trabalhando para corrigir o problema o mais rápido possível.",
        warning: "Tente acessar novamente mais tarde.",
      },

      oops: "Ooops!",
      groupDeleted: "Esse grupo foi excluído.",
      groupDeletedBody:
        "Entre em contato com o administrador do amigo secreto para entender o que aconteceu e pensar em uma solução com o seu grupo.",
      groupDeletedSuggestion: "Sugerimos que seja realizado um novo sorteio.",

      emailDeleted: "Esse e-mail foi excluído do sorteio.",
      emailDeletedSuggestion:
        "Entre em contato com o administrador do amigo secreto para entender o que aconteceu.",
      needHelp: "Precisa de ajuda? Acesse nossa página de",
      faq: "perguntas frequentes.",
    },
  },

  es: {
    Home: {
      slogan:
        "La solución para aquellos que están cansados de escribir y doblar pedacitos de papel para el sorteo de amigo secreto.",
      description: [
        "Papelzinho es la aplicación perfecta para realizar tus sorteos de amigo secreto con practicidad y sin papelitos de verdad",
        "Crea tantos grupos como quieras, ¡así puedes hacer un amigo secreto diferente con cada grupo! El número de participantes es infinito y todos pueden poner hasta 3 opciones de regalos.",
        "Cómo funciona",
        "El sorteo se realiza en la propia aplicación y todos los integrantes recibirán un correo electrónico con un enlace dirigido a la revelación de su amigo oculto. Es en este mismo sitio que cada uno puede escribir sus preferencias de regalo.",
        "¡Buena diversión!",
        "¿Tienes alguna duda o sugerencia? Háblanos a través de",
      ],

      rating: "Calificaciones y opiniones",
      reviews: [
        {
          title: "¡GENIAL!",
          body: "¡Muy bueno, muy fácil de hacer, solo tienes que ingresar los nombres ya con la opción de regalos para cada amigo secreto, ingresar el correo electrónico y listo! Sortea en los correos electrónicos y ninguno ve el del otro, ¡muy bueno! ¡Súper recomendado! ¡Felicitaciones al desarrollador!",
        },
        {
          title: "¡MUY bueno!",
          body: "¡Muy buena la aplicación! ¡Súper recomendado! El correo no tarda en llegar, es súper rápido y práctico y no tiene publicidad. ¡Lo recomiendo mucho, no tengo nada de qué quejarme sobre la aplicación! Lástima que no se puedan dar 6 estrellas.",
        },
        {
          title: "Excelente",
          body: "Muy fácil, con muchas opciones. Es fantástico, siempre lo estaré usando. Lo mejor es que no es necesario que todos estén reunidos en un lugar y ni siquiera hay riesgo de sacar el propio nombre. Ni siquiera hacer trampa. Un consejo es poner la función de envío por SMS.",
        },
        {
          title: "Genial",
          body: "Recientemente tuve un problema y me puse en contacto con ellos a través de Facebook y me respondieron rápido y resolvieron mi problema en cuestión de minutos. Están de enhorabuena por la aplicación y por la atención.",
        },
      ],
    },

    Header: {
      store: "Tienda de Papelzinho",
      title: "Sorteo | Papelzinho",
    },

    Draw: {
      slogan: "Amigo secreto sin doblar papelitos",
      store: "Tienda de Papelzinho",
      greeting: "Hola",
      youAre: "Estás en el",
      participants: "participantes",
      revealSecretFriend: "Revelar mi amigo secreto",
      whatMyFriendWants: "Lo que mi amigo quiere",
      didntFillGiftList: {
        header: "Este participante aún no ha completado la lista de regalos",
        body: "¿Qué tal dar un toque a la gente?",
      },
      edit: "Editar",
      othersParticipantsChoices: "Las elecciones de los otros participantes",
      ratings: "Ver la calificación de los otros participantes",
    },

    popUp: {
      warning: "¿Estás seguro de que no hay nadie espiando?",
      seeWhoIDrew: "Ver a quién saqué",
      store: "Tienda de Papelzinho",
      yourFriendIs: "Tu amigo es...",
      buyGift: "Comprar regalo",
      giftList: "Lista de regalos",
    },

    participantGiftList: {
      save: "Salvar",
      edit: "Editar",
      store: "Tienda de Papelzinho",
      whatMyFriendWants: "Lo que mi amigo quiere",

      didntFillGiftList: {
        header: "Este participante aún no ha completado la lista de regalos",
        body: "¿Qué tal dar un toque a la gente?",
      },
      myList: "Mi lista",
      optionsPlaceHolders: ["Opción 1", "Opción 2", "Opción 3", "Comentarios"],
      observations: "Observaciones",
      giftOptions: "Opciones de regalo",
      saving: "Guardando...",
    },

    Footer: {
      store: "Tienda de Papelzinho",
      aboutUs: "Sobre nosotros",
      download: "Descarga la aplicación",
    },

    FAQ: {
      title: "Preguntas Frecuentes",
    },

    Errors: {
      500: {
        title: "Algo salió mal",
        body: "Estamos trabajando para solucionar el problema lo antes posible.",
        warning: "Intenta acceder nuevamente más tarde.",
      },

      oops: "¡Uy!",
      groupDeleted: "Este grupo fue eliminado.",
      groupDeletedBody:
        "Ponte en contacto con el administrador del amigo secreto para entender qué sucedió y pensar en una solución con tu grupo.",
      groupDeletedSuggestion: "Sugerimos que se realice un nuevo sorteo.",

      emailDeleted: "Este correo electrónico fue eliminado del sorteo.",
      emailDeletedSuggestion:
        "Ponte en contacto con el administrador del amigo secreto para entender qué sucedió.",
      needHelp: "¿Necesitas ayuda? Accede a nuestra página de",
      faq: "preguntas frecuentes.",
    },
  },
}
